import request2 from "@/utils/request";
const controller = "/Teacher";

const getSchoolTeacherList = _ =>
  request2({
    url: `${controller}/GetSchoolTeacherList`,
    method: "get"
  });

export { getSchoolTeacherList };

import request from "@/utils/request-proxy";
/**
 * 获取列表 GET
 * @param {*} targetId
 */
export function getPageList(params) {
  return request({
    url: "/DataCenter/teacher/GetPageList",
    method: "get",
    params
  });
}
/**
 * 获取信息 GET
 * @param {*} id
 */
export function getInfo(params) {
  return request({
    url: "/DataCenter/teacher/GetInfo",
    method: "get",
    params
  });
}

/**
 * 获取当前工号老师信息 GET
 * @param {*} code
 */
export function getTeacherByCode(params) {
  return request({
    url: "/DataCenter/teacher/GetTeacherByCode",
    method: "get",
    params
  });
}

/**
 * 添加老师信息 POST
 * @param {*} data
 */
export function insert(data) {
  return request({
    url: "/DataCenter/teacher/Insert",
    method: "post",
    data
  });
}
/**
 * 批量添加老师信息 POST
 * @param {*} data
 */
export function batchInsert(data) {
  return request({
    url: "/DataCenter/teacher/BatchInsert",
    method: "post",
    data
  });
}
/**
 * 修改老师信息 POST
 * @param {*} data
 */
export function updateEntity(data) {
  return request({
    url: "/DataCenter/teacher/Update",
    method: "post",
    data
  });
}
/**
 * 用户转换 POST
 * @param {*} data
 */
export function transformToUser(data) {
  return request({
    url: "/DataCenter/teacher/TransformToUser",
    method: "post",
    data
  });
}
/**
 * 删除 POST
 * @param {*} data
 */
export function deleteEntity(data) {
  return request({
    url: "/DataCenter/teacher/Delete",
    method: "post",
    data
  });
}
/**
 * 批量删除 POST
 * @param {*} data
 */
export function batchDelete(data) {
  return request({
    url: "/DataCenter/teacher/BatchDelete",
    method: "post",
    data
  });
}

export function getImportJsonConfig(params) {
  return request({
    url: "/DataCenter/teacher/GetImportJsonConfig",
    method: "get",
    params
  });
}
