<template>
  <div class="main-content">
    <el-row>
      <el-col :span="24">
        <content-header :title-list="titleList"></content-header>
      </el-col>
    </el-row>
    <ImportForm v-if="show" :orgId="orgId" />
  </div>
</template>

<script>
import ContentHeader from "@/components/Common/ContentHeader";
import ImportForm from "./components/ImportForm";

export default {
  components: { ImportForm, ContentHeader },
  data() {
    return {
      titleList: [
        {
          text: "教师管理",
          isCur: false,
          link: ""
        },
        {
          text: "批量导入",
          isCur: true,
          link: ""
        }
      ],
      orgId:"",
      show: false
    };
  },
  created() {
    this.orgId = this.$route.query.orgId
    this.show = true;
  }
};
</script>
<style lang="scss" scoped></style>
