<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="formData"
    class="form-container"
    style="width:100%"
    label-width="120px"
  >
    <el-row class="row" type="flex" justify="center">
      <el-col class="form-content">
        <el-row class="sectionLine">
          <div class="inline divSection"></div>
          <div class="inline sectionTitle">批量导入</div>
          <div class="gray-box mt-24">
            <span>1、下载导入模版</span>
            <span class="tips">请根据提示信息完善表格内容</span>
            <span class="mt-8">
              <el-button
                :loading="loading"
                size="large"
                type="primary"
                plain
                @click="handleDownload"
                >下载模版</el-button
              >
            </span>
          </div>
          <div class="gray-box mt-24">
            <span>2、上传完善后的表格</span>
            <div class="upload-box">
              <upload-excel
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
              ></upload-excel>
            </div>
          </div>
          <div v-if="formData.TableData.length" class="gray-box mt-24">
            <div
              class="hover-danger"
              style="margin:20px 0 4px 0;font-style:italic"
            >
              选中的项目将直接生成账户,请谨慎操作！
            </div>
            <el-table
              class="import-list"
              :data="formData.TableData"
              border
              stripe
              style="width: 100%;"
              height="100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column
                v-for="item of formData.TableHeader"
                :key="item"
                :prop="item"
                :label="item"
              ></el-table-column>
            </el-table>
          </div>
          <div class="mt-24">
            <el-button
              v-if="formData.TableData.length"
              size="large"
              type="primary"
              @click="submitForm('form')"
              >导入</el-button
            >
            <el-button size="large" @click="cancle">{{
              formData.TableData.length ? "取消" : "返回"
            }}</el-button>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import UploadExcel from "@/components/base/UploadExcel";
import download from "@/mixins/download";
import { batchInsertTeacher } from "@/api/organization";
import { getImportJsonConfig } from "@/api/teacher";
export default {
  components: {
    UploadExcel
  },
  mixins: [download],
  data() {
    return {
      loading: false,
      formData: {
        OrganizationId: this.$route.query.orgId,
        TableData: [],
        TableHeader: []
      },
      isUploading: false,
      mapKeys: null,
      modelUrl:
        process.env.VUE_APP_BASE_API + "/Teacher/DownloadImportTemp"
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        const res = await getImportJsonConfig();
        this.mapKeys = JSON.parse(res.Result);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleDownload() {
      try {
        this.loading = true;
        const url = "/Teacher/DownloadImportTemp";
        await this.downloadXlsx(url);
      } catch (err) {
        console.log("err:", err);
      } finally {
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.formData.TableData = results;
      this.formData.TableHeader = header;
    },
    handleSelectionChange(selection) {
      if (selection.length) {
        this.formData.TableData.forEach(element => {
          element.IsCreatedUser = false;
        });
        selection.forEach(element => {
          element.IsCreatedUser = true;
        });
      } else {
        this.formData.TableData.forEach(element => {
          element.IsCreatedUser = false;
        });
      }
      console.log(this.formData.TableData);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // 定义需要转为int类型的键
          const numberKeys = ["TeachYear"];
          this.isUploading = true;
          let isSuccess = false;
          let message = "服务器错误！";
          const postData = JSON.parse(JSON.stringify(this.formData));
          // 改变导入数据的键为对应的英文字段
          postData.TableData.forEach(el => {
            for (const key in this.mapKeys) {
              if (el[key]) {
                if (numberKeys.indexOf(this.mapKeys[key]) !== -1) {
                  el[this.mapKeys[key]] = parseInt(el[key]);
                  delete el[key];
                } else {
                  el[this.mapKeys[key]] = el[key].toString();
                  delete el[key];
                }
              }
            }
          });
          try {
            const res = await batchInsertTeacher(postData);
            isSuccess = res.IsSuccess;
            message = res.Message;
            if (isSuccess) {
              this.$message.success(message || "保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isUploading = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误"
          });
          return;
        }
      });
    },
    cancle() {
      if (this.formData.TableData.length) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.row {
  flex: 1;
  padding: 40px 0;
}
.form-content {
  width: 900px;
}
.gray-box {
  padding: 24px;
  border-radius: 8px;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  .tips {
    color: #999999;
    margin-top: 8px;
  }
  .upload-box {
    padding: 40px;
    margin-top: 24px;
    background: #fff;
    border: 1px solid #dddddd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hover-danger {
    color: red;
  }
}
</style>
